<template>
  <div class="inspect_track">
    <div id="inspector-map" class="inner_wrap"></div>
    <div class="timeline-control">
      <TimeLine v-show="tab === 1"/>
    </div>
    <monitor-search ref="search"></monitor-search>
    <div class="zoom">
      <el-button
          icon="el-icon-plus"
          plain
          size="small"
          type="default"
          @click="setZoom(0)"
      ></el-button>
      <el-button
          icon="el-icon-minus"
          plain
          size="small"
          type="default"
          @click="setZoom(1)"
      ></el-button>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import TimeLine from "./common/TimeLine.vue";
import MonitorSearch from "./common/MonitorSearch";
// import TrackJson from "@/assets/geo/track.json";
import {mapControl} from "./common/mapControl";

export default {
  name: "inspect_track",
  components: {
    TimeLine,
    MonitorSearch,
  },
  data() {
    return {
      center: [116.98, 36.67],
      zoom: 5,
    };
  },
  computed: {
    ...mapState("inspectorTrack", ["tab"]),
  },
  beforeCreate() {
    window.map = null;
    // window.CanvasLayer = null;
    window.CanvasLayerPointer = null;
    window.canvasLayerBack = null;
    window.canvasLayer = null;
    window.pointCollection = null;
    window.canvasLayerRunning = null;
  },
  beforeDestroy() {
    window.map = null;
    // window.CanvasLayer = null;
    window.CanvasLayerPointer = null;
    window.canvasLayerBack = null;
    window.canvasLayer = null;
    window.pointCollection = null;
    window.canvasLayerRunning = null;
  },
  methods: {
    initMap() {
      const BMap = window.BMap;
      window.map = new BMap.Map("inspector-map", {enableMapClick: false});
      window.map.enableScrollWheelZoom();
      // 初始化地图,设置中心点坐标和地图级别
      window.map.centerAndZoom(
          new BMap.Point(this.center[0], this.center[1]),
          this.zoom
      );

      // 调用MonitorSearch的getInspectorList接口
      this.$refs.search.getInspectorList();
      this.$refs.search.allMarket();

      mapControl.initTrackPointOverlay();
    },
    setZoom(flag) {
      if (flag) {
        window.map.zoomOut();
      } else {
        window.map.zoomIn();
      }
    },
  },
  async mounted() {
    // await LoadMap("oW2UEhdth2tRbEE4FUpF9E5YVDCIPYih");
    this.initMap();

    // let returnObject = JSON.parse(TrackJson.returnObject);

    // console.log(returnObject.points);
    // setTimeout(() => {
    //   this.$refs.timeLine.render({
    //     map: this.map,
    //     data: returnObject.points,
    //   });
    // }, 2000);
  },
};
</script>

<style lang="less" scoped>
.inspect_track {
  height: calc(100% - 46px);

  overflow: hidden;
  position: relative;

  .inner_wrap {
    background: #fff;
    margin: 18px;
    height: calc(100% - 36px);
  }

  .timeline-control {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .zoom {
    position: absolute;
    right: 40px;
    bottom: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .el-button {
      width: 40px;
      text-align: center;
    }

    .el-button + .el-button {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
</style>
