export const mapControl = {
    /**
     * 初始化轨迹点信息覆盖物
     *
     */
    initTrackPointOverlay() {
        this.trackPointOverlay = function (point, type) {
            this._point = point;
            // this.type = 'trackpoint';
            this.type = type;
        };
        this.trackPointOverlay.prototype = new window.BMap.Overlay();
        this.trackPointOverlay.prototype.initialize = function (map) {
            this._map = map;
            let div = this._div = document.createElement('div');
            // div.className = 'trackpointOverlay';
            div.className = this.type;
            let innerDiv = document.createElement('div');
            innerDiv.className = 'trackpoint_in';
            div.appendChild(innerDiv);
            map.getPanes().labelPane.appendChild(div);
            return div;
        };
        this.trackPointOverlay.prototype.draw = function () {
            let map = this._map;
            let pixel = map.pointToOverlayPixel(this._point);
            this._div.style.left = pixel.x - 8 + 'px';
            this._div.style.top = pixel.y - 8 + 'px';
        };
    },
    /**
     * 添加轨迹点信息覆盖物
     *
     * @param {Object} point 点
     * @param {string} type 点类型
     */
    addTrackPointOverlay(point, type) {
        let myCompOverlay = new this.trackPointOverlay(point, type);
        window.map.addOverlay(myCompOverlay);
    },
    /**
     * 删除轨迹点信息覆盖物
     *
     * @param {string} type 类型，分为鼠标浮动和点击两种
     */
    removeTrackPointOverlay(type) {
        let overlays = window.map.getOverlays();
        let length = overlays.length;
        let trackPointOverlays = [];
        for (let i = 0; i < length; i++) {
            if (overlays[i].type === type) {
                trackPointOverlays.push(overlays[i]);
            }
        }
        for (let j = 0; j < trackPointOverlays.length; j++) {
            window.map.removeOverlay(trackPointOverlays[j]);
        }
    },
    /**
     * 初始化车辆信息详情和轨迹点详情infobox
     *
     * @param {Object} data 数据
     */
    setTrackInfoBox(data) {
        console.log(data);
        let infoContentFrontArr = [
            '<div class="carInfoWindow">',
            '<div class="carInfoHeader0">',
            '<abbr title="' + data.print + '">',
            data.print,
            '</abbr>',
            '</div>',
            '<div class="carInfoContent">'
        ];
        data.infor.map(function (item) {
            let itemPushArr = [
                '<div class="carInfoItem">',
                '<div class="infoItemTitle">',
                item[0],
                '</div>',
                '<div class="infoItemContent">',
                item[1],
                '</div>',
                '</div>'
            ];
            infoContentFrontArr.push(itemPushArr.join(''));
        });
        let infoContentNextArr = [
            '</div>',
            '<div class="infoControl">',
            '<div class="infoZoomIn" id="trackInfoZoomIn">',
            '放大',
            '</div>',
            '</div>',
            '</div>'
        ];
        // return;

        this.trackInfoBox = new window.BMapLib.InfoBox(
            window.map,
            infoContentFrontArr.concat(infoContentNextArr).join(''),
            {
                boxClass: 'carInfoBox',
                closeIconMargin: '15px 20px 0 0',
                alignBottom: false,
                // closeIconUrl: __uri('/static/images/closeinfowindow.png')
                closeIconUrl: ''
            }
        );
        this.trackInfoBox.open(data.point);
        const trackInfoZoomIn = document.getElementById('trackInfoZoomIn')
        trackInfoZoomIn.addEventListener('click', function () {
            // this.trackInfoBox.hide();
            window.map.zoomIn();
            window.map.addEventListener('moveend', function () {
                // that.trackInfoBox.show();
            });
        });
        // this.trackInfoBox.addEventListener('close', this.removeTrackPointOverlay('trackpointonOverlay'));
        window.map.panTo(data.point);
    },
    /**
     * 删除infobox
     *
     */
    removeTrackInfoBox() {
        window.map.removeOverlay(this.trackInfoBox);
        this.trackInfoBox = null;
    },
    getSpeed(speed) {
        var speedDesc
        if (speed >= 150) {
            speedDesc = ' - - ';
        } else if (speed >= 1 && speed < 150) {
            speedDesc = speed.toFixed(1) + 'km/h';
        } else {
            speedDesc = '静止';
        }
        return speedDesc;
    }
}
