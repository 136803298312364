<template>
  <div class="monitor">
    <el-card class="monitor-content">
      <div class="monitor-top">
        <div class="monitor-top-select"></div>
        <div class="monitor-top-tag">
          <ul style="width: 100%">
            <li
                :class="{ active: tab === 0 }"
                style="float: left"
                @click="changeTab(0)"
            >
              <svg-icon
                  icon-class="eye_realTime"
                  style="margin-right: 3px"
              ></svg-icon
              >
              实时监控
            </li>
            <li
                :class="{ active: tab === 1 }"
                style="float: right"
                @click="changeTab(1)"
            >
              <svg-icon
                  icon-class="track_check"
                  style="margin-right: 3px"
              ></svg-icon
              >
              轨迹查询
            </li>
          </ul>
        </div>
      </div>
      <div class="monitor-main">
        <!--轨迹查询-->
        <div v-show="tab === 1">
          <div class="monitor-search">
            <el-form :model="form">
              <el-form-item class="monitor-search-item">
                <!-- <fuzzy-query
                  size="small"
                  placeholder="请输入结算方名称"
                  :id.sync='form.settlementId'
                  :list="settleMentList"
                  action="GetSettleMentList"
                  @change="getNewTrackList"
                >
                </fuzzy-query> -->
                <el-input placeholder="结算方名称"/>
              </el-form-item>
              <el-form-item class="monitor-search-item">
                <el-input placeholder="巡检员名称"/>
                <!-- <fuzzy-query
                  size="small"
                  placeholder="请输入巡检员名称"
                  :id.sync='form.inspectorId'
                  :list="inspectorList"
                  action="GetInspector"
                   @change="getNewTrackList"
                >
                </fuzzy-query> -->
              </el-form-item>
              <el-form-item class="monitor-search-item">
                <span>选择日期</span>
                <!-- <el-date-picker
                  v-model="form.date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  @change="getNewTrackList"
                  :clearable="false"

                >
                </el-date-picker> -->
              </el-form-item>
            </el-form>
          </div>
          <div class="monitor-list">
            <div class="monitor-list-total">
              <span class="total">共计（{{ trackTotal }}人）</span>
            </div>
            <track-content
                :searchDate="form.date"
                :trackList="trackList"
            ></track-content>
            <div class="isPage">
              <el-pagination
                  v-if="trackTotal > 0"
                  :current-page="pageNo2"
                  :page-size="5"
                  :pager-count="5"
                  :total="trackTotal"
                  layout="prev, pager, next"
                  @current-change="currentChange2"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!--实时监控-->
        <div v-if="tab === 0">
          <div class="monitor-search">
            <el-form :model="form">
              <el-form-item class="monitor-search-item">
                <!-- <fuzzy-query
                  size="small"
                  placeholder="请输入结算方名称"
                  :id.sync='form.settlementId'
                  :list="settleMentList"
                  action="GetSettleMentList"
                  @change="getNewInspectorList"
                >
                </fuzzy-query> -->
                <span>根据结算方名称搜索</span>
              </el-form-item>
              <el-form-item class="monitor-search-item">
                <!-- <fuzzy-query
                  size="small"
                  placeholder="请输入车场名称"
                  :id.sync='form.parklotId'
                  :list="parklotList"
                  action="GetParklotList"
                  @change="getNewInspectorList"
                >
                </fuzzy-query> -->
                <span>根据车场名称搜索</span>
              </el-form-item>
            </el-form>
          </div>
          <div class="monitor-list">
            <div class="monitor-list-total">
              <span class="total">签到（{{ inspectorTotal }}）</span>
            </div>
            <div
                v-for="item of inspectors"
                :key="item.inspectorId"
                :class="{ 'is-active': item.active }"
                class="monitor-list-item"
                @click="checkDistanceBefore(item)"
            >
              <span>{{ item.inspectorName }}</span>
              <span style="float: right">{{ item.inspectorMileage }}km</span>
            </div>
          </div>
          <div class="isPage">
            <el-pagination
                v-if="inspectorTotal > 0"
                :current-page="pageNo"
                :page-size="5"
                :pager-count="5"
                :total="inspectorTotal"
                layout="prev, pager, next"
                @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import TrackContent from "./TrackContent";
import {mapState} from "vuex";
import bus from "@/common/EventBus";
import {getInspectorInfo, mapCoordinatePointDisplay, searchInspectors, trackTheQuery,} from "@/api/track";
import makerImg from "@/assets/images/track/carrun_2x.png";

export default {
  name: "monitor-search",
  components: {
    TrackContent,
  },
  data() {
    return {
      selectedRegions: [],
      form: {
        settlementId: "",
        inspectorId: "",
        parklotId: "",
        date: "",
      },
      inspectors: [],
      trackList: [],
      inspectorTotal: 0,
      trackTotal: 0,
      currentInspector: "",
      // 聚合实例
      markerClusterer: null,
      pageNo: 1,
      pageNo2: 1,
      allMarkers: [],
      xjymc: "",
    };
  },
  computed: {
    ...mapState("inspectorTrack", ["tab"]),
  },
  watch: {
    tab(val) {
      const zoom = window.map.getZoom();
      if (val === 1) {
        window.map.getPanes().markerPane.style.visibility = "hidden";
        if (window.canvasLayer) {
          window.canvasLayer.show();
          window.CanvasLayerPointer.show();
          window.canvasLayerBack.show();
          window.canvasLayerRunning && window.canvasLayerRunning.show();
        }
      } else if (val === 0) {
        if (zoom > 14) {
          window.map.getPanes().markerPane.style.visibility = "visible";
        }
        if (window.canvasLayer) {
          window.canvasLayer.hide();
          window.CanvasLayerPointer.hide();
          window.canvasLayerBack.hide();
          window.canvasLayerRunning && window.canvasLayerRunning.hide();
        }
      }
    },
  },
  //   components: { TrackContent },
  mounted() {
    bus.$on("noTrack", () => {
      // 未查询到轨迹，重新刷新巡检列表
      // this.pageNo = 1
      // this.getInspectorList()
      // 清除历史轨迹和点位
      if (window.map) window.map.clearOverlays();
      // const all = map.getOverlays()
      //   for(let i = 0; i < all.length; i++) {
      //     if(all[i].paneName) {
      //       map.removeOverlay(all[i])
      //     }
      //   }
    });
  },
  methods: {
    // 轨迹管理台Tab切换
    changeTab(tab) {
      this.$store.commit("inspectorTrack/CHANGE_TAB", tab);
      if (tab === 1 && this.trackList.length < 1) {
        this.getTrackList();
      }
    },
    async getTrackList() {
      const regionId = "";
      const params = {
        inspectorId: this.form.inspectorId,
        queryTime: this.form.date,
        regionId: regionId || "",
        settlementId: this.form.settlementId,
        pageNo: this.pageNo2,
        pageSize: 5,
      };
      try {
        const res = await trackTheQuery(params);
        const list = res.returnObject.list;
        list.forEach((item) => {
          item.active = false;
        });
        this.trackList = list;
        this.trackTotal = res.returnObject.total;
        return list[0];
      } catch (e) {
        this.trackList = [];
        this.trackTotal = 0;
        const all = window.map.getOverlays();
        for (let i = 0; i < all.length; i++) {
          if (all[i].paneName) {
            window.map.removeOverlay(all[i]);
          }
        }
      }
    },
    // 获取所有实时监控聚合经纬度标签
    async allMarket() {
      const regionId = "";
      const params = {
        parkingLotId: this.form.parklotId,
        regionId: regionId,
        settlementId: this.form.settlementId,
      };
      try {
        const res = await mapCoordinatePointDisplay(params);
        const list = res.returnObject;
        this.allMarkers = this.addMarkers(list);
      } catch (error) {
        console.error(error);
        this.allMarkers = [];
      }
    },
    // 生成聚合标注
    addMarkers(list) {
      if (this.markerClusterer) {
        this.markerClusterer.clearMarkers();
      }
      let markers = [];
      list.forEach((item) => {
        var pt = new window.BMap.Point(item.longitude, item.latitude);
        const myIcon = new window.BMap.Icon(
            makerImg,
            new window.BMap.Size(30, 35)
        );
        var marker = new window.BMap.Marker(pt, {icon: myIcon}); // 创建标注
        marker.setAnimation(window.BMAP_ANIMATION_DROP);
        item.target = marker;
        item.active = false;
        marker.addEventListener("click", () => {
          this.checkDistance(marker, item);
        });
        // map.addOverlay(marker)
        markers.push(marker);
      });
      this.markerClusterer = new window.BMapLib.MarkerClusterer(window.map, {
        markers: markers,
      });
      this.markerClusterer.setMaxZoom(14);
      return list;
    },
    // 分页
    currentChange(val) {
      this.pageNo = val;
      if (window.map) {
        const all = window.map.getOverlays();
        for (let i = 0; i < all.length; i++) {
          if (!all[i].paneName) {
            window.map.removeOverlay(all[i]);
          }
        }
      }
      this.getInspectorList();
    },
    // 分页2
    currentChange2(val) {
      this.pageNo2 = val;
      this.getTrackList();
    },
    // 查看轨迹
    async checkInspectorTrack(data) {
      this.xjymc = data.name;
      this.$store.commit("inspectorTrack/CHANGE_TAB", 1);
      // await this.$store.dispatch("GetInspector", this.xjymc);
      this.form.inspectorId = data.inspectorId;
      this.pageNo2 = 1;
      const item = await this.getTrackList();
      bus.$emit("tracklist", item);
    },
    // 实时监控巡检员列表
    async getInspectorList() {
      const regionId = "";
      const params = {
        parkingLotId: this.form.parklotId,
        regionId: regionId,
        settlementId: this.form.settlementId,
        pageNo: this.pageNo,
        pageSize: 5,
      };
      try {
        const res = await searchInspectors(params);
        const list = res.returnObject.list;
        // this.inspectors =  this.addMarkers(list)
        this.inspectors = list;
        this.inspectors.forEach((v) => {
          this.$set(v, "active", false);
        });
        this.inspectorTotal = res.returnObject.total;
        this.total = res.returnObject.total;
      } catch (error) {
        console.error(error);
        this.inspectors = [];
        this.inspectorTotal = 0;
        this.total = 0;
        if (window.map) window.map.clearOverlays();
      }
    },
    // 巡检员信息窗口
    checkDistanceBefore(item) {
      this.allMarkers.forEach((el) => {
        if (item.inspectorId === el.userId) {
          this.checkDistance(el.target, el);
        }
      });
    },
    async checkDistance(target, item) {
      const params = {
        inspectorId: item.userId,
        entityName: item.entityName,
      };
      this.inspectors.forEach((v) => {
        if (v.inspectorId === item.userId) {
          v.active = true;
          this.xjymc = v.inspectorName;
        } else {
          v.active = false;
        }
      });
      // item.active = true
      this.currentInspector = item;
      if (target && target.compOverlay) {
        const myCompOverlay = target.compOverlay;
        if (myCompOverlay.isVisible()) {
          myCompOverlay.hide();
        } else {
          myCompOverlay.show();
        }
        window.map.centerAndZoom(
            new window.BMap.Point(item.longitude, item.latitude),
            17
        );
        return false;
      } else {
        const res = await getInspectorInfo(params);
        const data = res.returnObject[0];
        this.addInspectionPane(target, data);
        window.map.centerAndZoom(
            new window.BMap.Point(item.longitude, item.latitude),
            17
        );
      }
    },
    // 自定义巡检信息覆盖物
    addInspectionPane(target, data) {
      const that = this;
      const latitude = target.getPosition().lat;
      const longitude = target.getPosition().lng;

      // 复杂的自定义覆盖物
      function ParklotContextPanel(point) {
        this._point = point;
      }

      ParklotContextPanel.prototype = new window.BMap.Overlay();
      // 初始化覆盖物
      ParklotContextPanel.prototype.initialize = function (map) {
        this._map = map;
        var div = (this._div = document.createElement("div"));
        var sd = (this._sd = document.createElement("div"));
        var closeEle = (this._closeEle = document.createElement("div"));
        closeEle.className = "close_plane el-icon-close";
        div.className = "parklot_plane";
        div.style.position = "absolute";
        div.style.zIndex = window.BMap.Overlay.getZIndex(this._point.lat);
        sd.className = "parklot_item";
        let QD = [];
        data.signAreaList.forEach((v) => {
          QD.push(v.parkingLotName + ":" + v.areaName.join("、"));
        });
        const tmpl = `
                    <div class="parklot_item"><span>${data.name}（${
            data.mobileNum
        }）</span></div>
                    <div class="parklot_item"><span>签到：${QD.join(
            "；"
        )}</span></div>
                    <div class="parklot_item"><span>上班：${
            data.signTime
        }</span></div>
                    <div class="parklot_item"><span>里程：${
            data.mileage
        }km</span></div>
                    `;
        div.innerHTML = tmpl;
        sd.className = "parklot_item";
        sd.innerHTML = `<span class="check_track">查看轨迹</span>`;
        div.onmouseout = function () {
          div.style.zIndex = "auto";
        };
        div.onmouseover = function () {
          div.style.zIndex = "9999";
        };
        div.onclick = function () {
          that.checkInspectorTrack(data);
        };
        closeEle.onclick = function (ev) {
          target.compOverlay.hide();
          ev.stopPropagation();
        };
        div.appendChild(closeEle);
        div.appendChild(sd);
        map.getPanes().markerPane.appendChild(div);
        this._div = div;
        this._sd = sd;
        this._closeEle = closeEle;
        return div;
      };
      ParklotContextPanel.prototype.draw = function () {
        var map = this._map;
        var pixel = map.pointToOverlayPixel(this._point);
        this._div.style.left = pixel.x - 135 + "px";
        this._div.style.top = pixel.y - 188 + "px";
      };
      var myCompOverlay = new ParklotContextPanel(
          new window.BMap.Point(longitude, latitude)
      );
      window.map.addOverlay(myCompOverlay);
      target.compOverlay = myCompOverlay;
    },
  },
};
</script>
<style lang="less" scoped>
.monitor {
  width: 320px;
  position: absolute;
  z-index: 100;
  top: 70px;
  left: 40px;
  box-shadow: 3px 3px 10px rgba(51, 59, 99, 0.5);

  .monitor-content {
    width: 320px;

    .monitor-top {
      background-color: #333b63;
      width: 320px;
      padding: 5px 10px 5px 10px;

      .monitor-top-tag {
        width: 70%;
        margin: 0 auto;
        margin-top: 15px;

        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          overflow: hidden;

          li {
            color: #fff;
            display: inline-block;
            height: 25px;
            cursor: pointer;
          }

          .active {
            border-bottom: 2px solid #FFFFFF;
          }
        }
      }
    }

    .monitor-main {
      width: 100%;
      padding-bottom: 20px;

      .monitor-search {
        margin: 15px 15px 25px 15px;

        .monitor-search-item {
          position: relative;

          .search-button {
            position: absolute;
            right: 0;
            top: 4px;
          }
        }
      }

      .monitor-list {
        max-height: 220px;
        margin: 0 10px;

        .monitor-list-total {
          height: 25px;
          border-bottom: 1px solid #ebebeb;

          .total {
            padding-left: 5px;
            border-left: 3px solid #0768FD;
          }
        }

        .monitor-list-item {
          height: 25px;
          border-bottom: 1px solid #ebebeb;
          margin-top: 10px;
          color: #565d68;
          cursor: pointer;
        }

        .monitor-list-item.is-active {
          border-bottom: 1px solid #0768FD!important;
        }
      }
    }
  }
}

.isPage {
  text-align: right;
}
</style>
<style lang="less">
.monitor .el-card__body {
  padding: 0 !important;
}

.monitor .el-form-item,
.monitor .el-form-item__content,
.monitor .el-input,
.monitor .el-input__inner {
  height: 35px;
}

.monitor .el-form-item {
  margin-bottom: 15px;
}

.monitor .monitor-top-select input {
  border: none !important;
  background-color: #5b6182;
}

.monitor .el-cascader__label,
.monitor .el-cascader__label span {
  color: #fff;
}

.monitor .monitor-top-select .el-form-item,
.monitor .monitor-top-select .el-form-item__content,
.monitor .monitor-top-select .el-input,
.monitor .monitor-top-select .el-input__inner {
  height: 28px;
}

.el-form-item__content .el-select,
.el-form-item__content .el-date-editor {
  width: 100%;
}

.el-date-editor--date input {
  font-size: 14px;
}

.parklot_plane {
  position: absolute;
  width: 360px;
  min-height: 102px;
  background: rgba(51, 59, 99, 0.8);
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff;
  padding-bottom: 12px;
  z-index: 3000;

  .parklot_item {
    margin-top: 12px;
    padding-left: 20px;
  }

  .check_track {
    position: relative;
    float: right;
    display: inline-block;
    padding: 4px 6px;
    background-color: #7d829b;
    margin-right: 20px;
    border-radius: 4px;
  }
}

.parklot_plane:after {
  content: "";
  position: absolute;
  left: 125px;
  bottom: -20px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: rgba(51, 59, 99, 0.8);
}

.parklot_plane .close_plane {
  position: absolute;
  padding-top: 12px;
  width: 24px;
  top: 0;
  right: 0;
  // z-index: 3001;
}
</style>
