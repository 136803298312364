import TrackTheQueryJSON from '@/assets/track/trackTheQuery';
import getTrackJSON from '@/assets/track/getTrack';
import SearchInspectors from '@/assets/track/searchInspectors';
import MapCoordinatePointDisplay from '@/assets/track/MapCoordinatePointDisplay';
import GetInspectorWorkStatus from '@/assets/track/getInspectorWorkStatus';

export function trackTheQuery(params) {
    console.log(params);
    return TrackTheQueryJSON;
}

export function getTrack(params) {
    console.log(params);
    return getTrackJSON;
}

// 获取签到的巡检员列表
export function searchInspectors(params) {
    console.log(params);
    return SearchInspectors;
}

export function mapCoordinatePointDisplay(params) {
    console.log(params);
    return MapCoordinatePointDisplay;
}

export function getInspectorInfo(params) {
    console.log(params);
    return GetInspectorWorkStatus;
}