<template>
  <div class="visible timeline">
    <div class="timelineControl">
      <div
          :class="playOrPause"
          title="播放或暂停"
          @click="handlePlayOrPause"
      ></div>
      <div class="timelineSlow" title="减速" @click="handleSlow"></div>
      <div class="timelineQuick" title="加速" @click="handleQuick"></div>
    </div>
    <div
        ref="timeLineMain"
        class="timelineMain"
        @click="handleTimelineClick"
        @mouseout="handleOffLabel"
        @mouseover="handleHoverLabel"
    >
      <div
          v-for="(index, key) of timeCount"
          :key="index"
          :class="{ timeHourFirst: key === 0, timeHourFinal: key === 23 }"
          class="timeHour"
      ></div>
      <div
          v-for="key of timeNumber"
          :key="key + Math.random()"
          :style="{ left: key * 29.6 - 1 + 'px' }"
          class="timeNumber"
      >
        {{ key }}
      </div>

      <div
          ref="timeLineProgress"
          :style="{ left: progress + 'px' }"
          class="timelineProgress"
          @mousedown="handleProgressDragStart"
      ></div>
      <div
          v-for="item of dataPart"
          :key="item.id"
          :style="{
          left: item.start_time + 'px',
          width: item.end_time - item.start_time + 'px',
        }"
          class="runPart"
      ></div>
      <div
          v-show="labelVisible"
          :style="{ left: label + 'px' }"
          class="timelineLabel"
      >
        <div class="timelineLabelcontent">{{ hovertime }}</div>
        <div class="timelineLabelpointer"></div>
      </div>
      <div class="caliperA" style="left: 0">
        <div class="caliperLine"></div>
        <div class="caliperPointerA"></div>
      </div>
      <div class="caliperB" style="left: 721px">
        <div class="caliperLine"></div>
        <div class="caliperPointerB"></div>
      </div>
      <div class="caliperPartA" style="width: 0"></div>
      <div class="caliperPartB" style="width: 0"></div>
    </div>
  </div>
</template>
<script>
import bus from "@/common/EventBus";
import $ from "jquery";

window.canvasLayerRunning = null;
export default {
  name: "time-line",
  data() {
    return {
      timelineLong: 721,
      // 时间轴位置
      progress: 0,
      // 当前拖动的卡尺
      caluperCurrent: "",
      // 当前有数据的时间段数组
      dataPart: [],
      // 时间点数量
      timeCount: [],
      timeNumber: [],
      // 初始鼠标拖动位置
      initMouseX: 0,
      // 当前时间轴位置
      currentProgress: 0,
      // 当前时间轴位置对应的pageX
      currentPageX: 0,
      // 浮动时间
      hovertime: "0:0",
      // 时间浮动偏移量
      label: 0,
      // lable可见性
      labelVisible: false,
      // 播放按钮状态
      playOrPause: "timelinePlay",
      // 播放速度，常规速度为0.1/frame
      // 减速为 0.08,0.06,0.04,0.02,0.01
      // 加速为 0.12,0.14,0.16,0.18,0.20
      playSpeed: [
        0.01, 0.02, 0.04, 0.06, 0.08, 0.1, 0.12, 0.14, 0.16, 0.18, 0.2,
      ],
      // 当前播放速度位置
      playSpeedIndex: 2,
      // 当天起始时间时间错
      initTimeStamp: 0,
      totalPointData: [],
    };
  },
  // computed: {
  //   ...mapGetters(["totalPointData", "initTimeStamp"]),
  // },
  created() {
    this.initTimeCount();
    bus.$on("trackRoute", (data) => {
      this.listenTrackRoute(data);
    });
  },
  methods: {
    /**
     * 初始化时间轴
     *
     */
    initTimeCount() {
      const tempA = [];
      const tempB = [];
      for (let i = 0; i < 24; i++) {
        tempA[i] = i;
      }
      this.timeCount = tempA;
      for (let i = 0; i < 25; i++) {
        tempB[i] = i;
      }
      this.timeNumber = tempB;
    },
    /**
     * DOM操作回调，播放停止轨迹
     *
     * @param {object} event 事件对象
     */
    handlePlayOrPause(event) {
      if (this.totalPointData.length === 0) {
        return;
      }
      window.requestAnimationFrame =
          window.requestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.msRequestAnimationFrame;
      var newStatus = "";
      var step = () => {
        var speed = this.playSpeed[this.playSpeedIndex];
        this.progress = this.progress + speed;
        this.currentProgress = this.currentProgress + speed;
        this.currentPageX = this.currentPageX + speed;
        this.setRunningPointByProgress(this.progress + speed);
        if (this.progress + speed > this.timelineLong) {
          newStatus = "timelinePlay";
          this.playOrPause = newStatus;
          return;
        }
        if (this.playOrPause === "timelinePause") {
          requestAnimationFrame(step);
        }
      };
      if (event.target.className === "timelinePause") {
        newStatus = "timelinePlay";
      } else {
        newStatus = "timelinePause";
        requestAnimationFrame(step);
      }
      this.playOrPause = newStatus;
    },
    /**
     * DOM操作回调，减速播放
     *
     * @param {object} event 事件对象
     */
    handleSlow() {
      if (this.totalPointData.length === 0) {
        return;
      }
      if (this.playSpeedIndex === 0) {
        // Todo
      } else {
        this.playSpeedIndex = this.playSpeedIndex - 1;
      }
    },
    /**
     * DOM操作回调，加速播放
     *
     * @param {object} event 事件对象
     */
    handleQuick() {
      if (this.totalPointData.length === 0) {
        return;
      }
      if (this.playSpeedIndex === 10) {
        // Todo
      } else {
        this.playSpeedIndex = this.playSpeedIndex + 1;
      }
    },
    /**
     * DOM操作回调，拖动时间轴位置
     *
     * @param {object} event 事件对象
     */
    handleProgressDragStart(event) {
      if (this.totalPointData.length === 0) {
        return true;
      }
      this.initMouseX = event.clientX;
      $(document).on("mousemove", this.onProgessDrag);
      $(document).on("mouseup", this.onProgressDragMouseUp);
    },
    /**
     * DOM操作回调，拖动时间轴位置
     *
     * @param {object} event 事件对象
     */
    handleProgressDragEnd() {
      if (this.totalPointData.length === 0) {
        return;
      }
      $(document).off("mousemove", this.onProgessDrag);
      $(document).off("mouseup", this.onProgressDragMouseUp);
      this.currentProgress = this.progress;
      this.setRunningPointByProgress(this.progress);
    },
    /**
     * view 内部 拖动事件监听
     *
     * @param {object} event 事件对象
     */
    onProgessDrag(event) {
      const x = event.clientX - this.initMouseX;
      const newProgress = x + this.currentProgress;

      if (newProgress >= 0 && newProgress <= this.timelineLong) {
        this.progress = newProgress;
      }
      this.setRunningPointByProgress(newProgress);
      this.handleHoverLabel(event);
    },
    /**
     * view 内部 拖动抬起鼠标
     *
     * @param {object} event 事件对象
     */
    onProgressDragMouseUp(event) {
      this.handleProgressDragEnd();
      this.currentPageX = event.clientX;
    },
    /**
     * view 内部 根据时间轴位置设置轨迹点位置
     *
     * @param {number} progress 时间戳
     */
    setRunningPointByProgress(progress) {
      const point = this.getPointByTime(this.getTimeByPx(progress + 0));
      if (point.loc_time !== undefined) {
        this.setRunningPoint(point);
      }
    },
    /**
     * view 内部 根据数据点绘制实时位置
     *
     * @param {object} data 数据点
     */
    setRunningPoint(data) {
      const update = function () {
        const ctx = this.canvas.getContext("2d");
        if (!ctx) {
          return;
        }
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        const point = new window.BMap.Point(data.longitude, data.latitude);
        const pixel = window.map.pointToPixel(point);

        ctx.beginPath();
        ctx.strokeStyle = "#d0d4d7";
        ctx.arc(pixel.x, pixel.y, 35, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.beginPath();
        ctx.fillStyle = "rgba(35, 152, 255, 0.14)";
        ctx.arc(pixel.x, pixel.y, 34, 0, 2 * Math.PI);
        ctx.fill();
        ctx.beginPath();
        ctx.strokeStyle = "#c2c2c4";
        ctx.arc(pixel.x, pixel.y, 8, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.beginPath();
        ctx.fillStyle = "#fff";
        ctx.arc(pixel.x, pixel.y, 7, 0, 2 * Math.PI);
        ctx.fill();
        ctx.beginPath();
        ctx.fillStyle = "#1496ff";
        ctx.arc(pixel.x, pixel.y, 2.6, 0, 2 * Math.PI);
        ctx.fill();
      };
      if (typeof window.canvasLayerRunning !== "undefined") {
        window.canvasLayerRunning.options.update = update;
        window.canvasLayerRunning._draw();
        return;
      }

      window.canvasLayerRunning = new window.CanvasLayer({
        map: window.map,
        update: update,
        zIndex: 100,
      });
    },
    /**
     * view 内部 根据时间获取数据点
     *
     * @param {number} time 时间戳
     * @return {object} 数据点
     */
    getPointByTime(time) {
      var point = {};
      var totalPoint = this.totalPointData;
      if (time < totalPoint[0].loc_time) {
        point = totalPoint[0];
        return point;
      }
      if (time > totalPoint[totalPoint.length - 1].loc_time) {
        point = totalPoint[totalPoint.length - 1];
        return point;
      }
      for (var i = 0; i < totalPoint.length - 1; i++) {
        if (
            time >= totalPoint[i].loc_time &&
            time <= totalPoint[i + 1].loc_time
        ) {
          point = totalPoint[i];
          break;
        }
      }
      return point;
    },
    /**
     * view 内部 根据时间轴位置获取对应数据中时间点
     *
     * @param {number} px 像素位置
     * @return {number} 时间戳
     */
    getTimeByPx(px) {
      var time = 0;
      time = px * 120 + this.initTimeStamp;
      return time;
    },
    /**
     * DOM操作回调，鼠标浮动到时间轴上后显示事件label
     *
     * @param {Object} event 事件对象
     */
    handleHoverLabel(event) {
      if (event.target.className.indexOf("caliperPointer") > -1) {
        return;
      }
      const x = event.clientX - $(".timelineMain").offset().left;
      // 一像素两分钟
      const time = x * 120;
      const hour = parseInt(time / (60 * 60), 10);
      let min = parseInt((time % (60 * 60)) / 60, 10);
      this.labelVisible = true;
      if (
          hour >= 0 &&
          hour <= 24 &&
          min >= 0 &&
          min <= 59 &&
          hour * 100 + min <= 2400
      ) {
        min = min >= 10 ? min : "0" + min;
        this.label = x;
        this.hovertime = hour + ":" + min;
      }
    },
    /**
     * DOM操作回调，鼠标移动开关闭label的显示
     *
     * @param {Object} event 事件对象
     */
    handleOffLabel() {
      this.labelVisible = false;
    },
    /**
     * DOM操作回调，点击时间轴跳跃时间
     *
     * @param {object} event 事件对象
     */
    handleTimelineClick(event) {
      if (this.totalPointData.length === 0) {
        return;
      }
      if (
          event.target.className === "timelineProgress" ||
          event.target.className.indexOf("caliperPointer") > -1
      ) {
        return;
      }
      this.jumpTime(event.clientX);
    },
    /**
     * DOM操作回调，点击时间轴跳跃时间
     *
     * @param {number} clientx 偏移
     */
    jumpTime(clientx) {
      const x = clientx - $(".timelineMain").offset().left;
      this.progress = x;
      this.currentProgress = x;
      this.currentPageX = clientx;
      this.setRunningPointByProgress(x);
    },
    /**
     * view 内部 根据时间戳获取时间轴像素位置
     *
     * @param {number} time 时间戳
     * @return {number} 像素位置
     */
    getPxByTime(time) {
      var px = 0;
      // 像素 = (当前时间戳 + （北京时区 * 60 * 60））% 一天的秒) / (一个时间轴像素代表的秒数)
      px = ((time + 28800) % 86400) / 120;
      return px;
    },
    /**
     * 响应Store trackroute事件，绘制时间轴
     *
     * @param {data} 轨迹数据
     */
    listenTrackRoute(data) {
      // this.initCaliper()
      if (data.length === 0) {
        return;
      }
      // this.$store.dispatch('GET_TOTALPOINTS', data)
      this.totalPointData = data;
      var timePart = [{}];
      var pxPart = [{}];
      var j = 0;
      var date = new Date(data[0].loc_time * 1000);
      this.initTimeStamp =
          data[0].loc_time -
          (date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds());
      // this.$store.dispatch('SET_INITTIME', initTimeStamp)
      timePart[j].start_time = data[0].loc_time;
      pxPart[j].start_time = this.getPxByTime(data[0].loc_time);
      for (var i = 0; i < data.length - 1; i++) {
        if (data[i + 1].loc_time - data[i].loc_time <= 5 * 60) {
          timePart[j].end_time = data[i + 1].loc_time;
          pxPart[j].end_time = this.getPxByTime(data[i + 1].loc_time);
        } else {
          j++;
          timePart[j] = {};
          timePart[j].start_time = data[i + 1].loc_time;
          pxPart[j] = {};
          pxPart[j].start_time = this.getPxByTime(data[i + 1].loc_time);
        }
      }
      this.dataPart = pxPart;

      this.progress = pxPart[0].start_time - 0;
      this.currentProgress = pxPart[0].start_time - 0;
      this.currentPageX = $(".timelineProgress").offset().left + 20;
      this.initMouseX = $(".timelineProgress").offset().left + 20;
      if (typeof window.canvasLayerRunning !== "undefined") {
        window.map.removeOverlay(window.canvasLayerRunning);
        window.canvasLayerRunning = undefined;
      }
      this.playOrPause = "timelinePlay";
      this.setRunningPointByProgress(pxPart[0].start_time);
    },
  },
};
</script>
<style lang="less" scped>
.timeline {
  position: absolute;
  width: 870px;
  height: 50px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  right: 40px;
  bottom: 50px;
  box-shadow: 1px 1px 3px 1px #cbcbcb;

  .timelineControl {
    width: 130px;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;

    .timelinePlay {
      margin-left: 15px;
      margin-top: 5px;
      width: 40px;
      height: 40px;
      background-image: url(../../../../../assets/images/track/play_2x.png);
      background-size: 40px 40px;
    }

    .timelinePause {
      margin-left: 15px;
      margin-top: 5px;
      width: 40px;
      height: 40px;
      background-size: 40px 40px;
      background-image: url(../../../../../assets/images/track/pause_2x.png);
    }

    .timelineSlow,
    .timelineQuick {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      opacity: 1;
    }

    .timelineSlow {
      width: 35px;
      background-image: url(../../../../../assets/images/track/slow_2x.png);
      background-size: 28px 28px;
    }

    .timelineSlow:hover {
      background-image: url(../../../../../assets/images/track/slowhover_2x.png);
    }

    .timelineQuick {
      width: 35px;
      background-image: url(../../../../../assets/images/track/quick_2x.png);
      background-size: 28px 28px;
    }

    .timelineQuick:hover {
      background-image: url(../../../../../assets/images/track/quickhover_2x.png);
    }
  }

  .timelineMain {
    margin-left: 12px;
    width: 721px;
    float: left;
    height: 100%;
    position: relative;
    cursor: pointer;

    .timeHourFirst {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }

    .timeHourFinal {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
      border-right: 1px solid #a3a3a4;
    }

    .timeHour {
      height: 6px;
      border-bottom: 1px solid #c2c2c4;
      border-top: 1px solid #c2c2c4;
      border-left: 1px solid #a3a3a4;
      width: 30px;
      float: left;
      margin-top: 22px;
      background-color: #c2c2c4;
    }

    .timeNumber {
      position: absolute;
      height: 15px;
      width: 15px;
      font-size: 13px;
      bottom: 2px;
      color: #222;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .runPart {
      background-color: #2398ff;
      height: 6px;
      top: 22px;
      position: absolute;
      border: 1px solid #218eef;
    }

    .timelineProgress {
      background-image: url(../../../../../assets/images/track/progress.png);
      height: 40px;
      width: 40px;
      position: absolute;
      cursor: pointer;
      top: 4px;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 10;
      margin-left: -20px;
    }

    .caliperA,
    .caliperB {
      height: 60px;
      position: absolute;
      width: 20px;
      margin-left: -10px;

      .caliperLine {
        height: 50px;
        width: 2px;
        margin: 0 auto;
        background-color: #45a1f3;
      }

      .caliperPointerA {
        border-right-color: #45a1f3;
      }

      .caliperPointerA,
      .caliperPointerB {
        height: 0;
        width: 0;
        border: 10px solid #45a1f3;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-left-width: 10px;
        border-right-width: 10px;
        cursor: pointer;
      }
    }

    .timelineLabel {
      position: absolute;
      height: 30px;
      width: 44px;
      top: -33px;
      margin-left: -22px;
      z-index: 20;

      .timelineLabelcontent {
        width: 100%;
        height: 25px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 2px;
        color: #efefef;
        line-height: 27px;
        text-align: center;
        font-size: 12px;
      }

      .timelineLabelpointer {
        height: 0;
        width: 0;
        border: 6px solid rgba(0, 0, 0, 0.7);
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
        margin-left: 16px;
      }
    }
  }
}
</style>
