<template>
  <div>
    <div
        v-for="(item, key) of trackList"
        :key="key"
        :class="{ 'is-active': item.active }"
        class="monitor-list-item"
        @click="handleSelectTrack(item)"
    >
      <span>{{ item.inspectorName }}</span> &nbsp;
      <svg-icon
          :icon-class="item.workStatus === '0' ? 'offLine' : 'onLine'"
      ></svg-icon>
      <span style="float: right">{{ item.inspectorMileage || 0.0 }}km</span>
    </div>
  </div>
</template>

<script>
import bus from "@/common/EventBus";
import {getTrack} from "@/api/track";
import startPoint from "@/assets/images/track/startpoint.png";
import endPoint from "@/assets/images/track/endpoint.png";
import {mapControl} from "./mapControl";

export default {
  name: "track-content",
  props: {
    trackList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    searchDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 当前选中轨迹
      currentTrack: {},
      // 空白entity列表，占位用
      blankEntityList: [],
      // 当前选择的entity名
      currentEntityName: "",
      // 当前选中的entity显示的内容
      currentEntityPrint: "",
      // 是否是第一次点击绘制
      first: true,
      // 当前选中的track的全天完整数据
      currentTrackData: [],
    };
  },
  created() {
    bus.$on("tracklist", (item) => {
      this.getInspectorTrack(item);
    });
  },
  methods: {
    /**
     * 处理选中某条历史轨迹
     */
    handleSelectTrack(item) {
      this.getInspectorTrack(item);
    },
    /**
     * 查询巡检员历史轨迹
     */
    async getInspectorTrack(item) {
      // if (item.inspectorCode === this.currentEntityName) {
      //   return
      // } else {
      //   this.currentEntityName = item.inspectorCode
      // }
      console.log(item, "item");
      const params = {
        inspectorId: item.inspectorId,
        searchDate: this.searchDate,
      };
      const res = await getTrack(params);
      const data = res.returnObject && JSON.parse(res.returnObject);
      if (data && data.points && data.points.length > 0) {
        this.trackList.forEach((v) => {
          v.active = false;
        });
        item.active = true;
        this.currentTrackData = data.points;
        // 绘制地图轨迹
        this.drawTrack();
        // 初始化卡尺
        bus.$emit("trackRoute", this.currentTrackData);
      } else {
        // this.$message.warning('暂无轨迹记录请重试！')
        bus.$emit("noTrack");
      }
    },
    getLocalTime: function (time) {
      var d = new Date(parseInt(time) * 1000);
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var hour = d.getHours();
      var minute = d.getMinutes();
      var second = d.getSeconds();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return (
          d.getFullYear() +
          "-" +
          month +
          "-" +
          day +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
      );
    },
    /**
     * view内部，绘制轨迹线路
     *
     * @param {Array} data 轨迹数据 可选
     * @param {number} starttime 时间区间起点 可选
     * @param {number} endtime 时间区间终点 可选
     */
    drawTrack(data, starttime, endtime) {
      if (!data) {
        data = this.currentTrackData;
      }
      let that = this;
      let totalPoints = [];

      if (data.length === 0) {
        return;
      }
      if (!starttime) {
        starttime = data[0].loc_time;
      }
      if (!endtime) {
        endtime = data[data.length - 1].loc_time;
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].loc_time >= starttime && data[i].loc_time <= endtime) {
          let tempPoint = new window.BMap.Point(
              data[i].longitude,
              data[i].latitude
          );
          tempPoint.speed = data[i].speed ? data[i].speed : 0;
          tempPoint.loc_time = data[i].loc_time;
          tempPoint.height = data[i].height;
          tempPoint.radius = data[i].radius;
          tempPoint.print = that.currentEntityPrint;
          tempPoint.printTime = this.getLocalTime(data[i].loc_time);
          tempPoint.printSpeed = mapControl.getSpeed(data[i].speed);
          tempPoint.lnglat =
              data[i].longitude.toFixed(2) + "," + data[i].latitude.toFixed(2);
          totalPoints.push(tempPoint);
        }
      }
      if (that.first) {
        window.map.setViewport(totalPoints, {margins: [80, 0, 0, 200]});
      }

      let updatePointer = function () {
        let ctx = this.canvas.getContext("2d");
        if (!ctx) {
          return;
        }
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        if (totalPoints.length !== 0) {
          let pixelPart = 0;
          const pixelPartUnit = 40;
          for (let i = 0, len = totalPoints.length; i < len - 1; i = i + 1) {
            let pixel = window.map.pointToPixel(totalPoints[i]);
            let nextPixel = window.map.pointToPixel(totalPoints[i + 1]);
            pixelPart =
                pixelPart +
                Math.pow(
                    Math.pow(nextPixel.x - pixel.x, 2) +
                    Math.pow(nextPixel.y - pixel.y, 2),
                    0.5
                );
            if (pixelPart <= pixelPartUnit) {
              continue;
            }
            pixelPart = 0;
            ctx.beginPath();

            if (
                totalPoints[i + 1].loc_time - totalPoints[i].loc_time <=
                5 * 60
            ) {
              // 箭头一共需要5个点：起点、终点、中心点、箭头端点1、箭头端点2

              let midPixel = new window.BMap.Pixel(
                  (pixel.x + nextPixel.x) / 2,
                  (pixel.y + nextPixel.y) / 2
              );

              //起点终点距离
              let distance = Math.pow(
                  Math.pow(nextPixel.x - pixel.x, 2) +
                  Math.pow(nextPixel.y - pixel.y, 2),
                  0.5
              );
              // 箭头长度
              let pointerLong = 4;
              let aPixel = {};
              let bPixel = {};
              if (nextPixel.x - pixel.x === 0) {
                if (nextPixel.y - pixel.y > 0) {
                  aPixel.x = midPixel.x - pointerLong * Math.pow(0.5, 0.5);
                  aPixel.y = midPixel.y - pointerLong * Math.pow(0.5, 0.5);
                  bPixel.x = midPixel.x + pointerLong * Math.pow(0.5, 0.5);
                  bPixel.y = midPixel.y - pointerLong * Math.pow(0.5, 0.5);
                } else if (nextPixel.y - pixel.y < 0) {
                  aPixel.x = midPixel.x - pointerLong * Math.pow(0.5, 0.5);
                  aPixel.y = midPixel.y + pointerLong * Math.pow(0.5, 0.5);
                  bPixel.x = midPixel.x + pointerLong * Math.pow(0.5, 0.5);
                  bPixel.y = midPixel.y + pointerLong * Math.pow(0.5, 0.5);
                } else {
                  continue;
                }
              } else {
                let k0 =
                    (-Math.pow(2, 0.5) * distance * pointerLong +
                        2 * (nextPixel.y - pixel.y) * midPixel.y) /
                    (2 * (nextPixel.x - pixel.x)) +
                    midPixel.x;
                let k1 = -((nextPixel.y - pixel.y) / (nextPixel.x - pixel.x));
                let a = Math.pow(k1, 2) + 1;
                let b = 2 * k1 * (k0 - midPixel.x) - 2 * midPixel.y;
                let c =
                    Math.pow(k0 - midPixel.x, 2) +
                    Math.pow(midPixel.y, 2) -
                    Math.pow(pointerLong, 2);

                aPixel.y = (-b + Math.pow(b * b - 4 * a * c, 0.5)) / (2 * a);
                bPixel.y = (-b - Math.pow(b * b - 4 * a * c, 0.5)) / (2 * a);
                aPixel.x = k1 * aPixel.y + k0;
                bPixel.x = k1 * bPixel.y + k0;
              }
              ctx.moveTo(aPixel.x, aPixel.y);
              ctx.lineWidth = 2;
              ctx.strokeStyle = "#eee";
              ctx.lineTo(midPixel.x, midPixel.y);
              ctx.lineTo(bPixel.x, bPixel.y);
              ctx.lineCap = "round";
            }
            if (
                totalPoints[i].loc_time >= starttime &&
                totalPoints[i + 1].loc_time <= endtime
            ) {
              ctx.stroke();
            }
          }
        }
      };
      let updateBack = function () {
        let nextArray = [];
        let ctx = this.canvas.getContext("2d");
        if (!ctx) {
          return;
        }
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        if (totalPoints.length !== 0) {
          var lines = 1;

          for (let i = 0, len = totalPoints.length; i < len - 1; i++) {
            let pixel = window.map.pointToPixel(totalPoints[i]);
            let nextPixel = window.map.pointToPixel(totalPoints[i + 1]);
            ctx.beginPath();

            ctx.moveTo(pixel.x, pixel.y);
            if (
                totalPoints[i + 1].loc_time - totalPoints[i].loc_time <=
                5 * 60
            ) {
              // 绘制轨迹的时候绘制两次line，一次是底色，一次是带速度颜色的。目的是实现边框效果
              ctx.lineWidth = 10;
              ctx.strokeStyle = "#8b8b89";
              ctx.lineTo(nextPixel.x, nextPixel.y);
              ctx.lineCap = "round";
            } else {
              lines = lines + 1;
              nextArray.push([pixel, nextPixel]);
            }
            if (
                totalPoints[i].loc_time >= starttime &&
                totalPoints[i + 1].loc_time <= endtime
            ) {
              ctx.stroke();
            }
          }
        }
      };
      let update = function () {
        let nextArray = [];
        let ctx = this.canvas.getContext("2d");
        if (!ctx) {
          return;
        }
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        if (totalPoints.length !== 0) {
          var lines = 1;
          for (let i = 0, len = totalPoints.length; i < len - 1; i++) {
            let pixel = window.map.pointToPixel(totalPoints[i]);
            let nextPixel = window.map.pointToPixel(totalPoints[i + 1]);
            ctx.beginPath();
            ctx.moveTo(pixel.x, pixel.y);
            if (
                totalPoints[i + 1].loc_time - totalPoints[i].loc_time <=
                5 * 60
            ) {
              // 绘制带速度颜色的轨迹
              ctx.lineCap = "round";
              ctx.lineWidth = 8;
              let grd = ctx.createLinearGradient(
                  pixel.x,
                  pixel.y,
                  nextPixel.x,
                  nextPixel.y
              );
              let speed = totalPoints[i].speed;
              let speedNext = totalPoints[i + 1].speed;
              grd.addColorStop(0, that.getColorBySpeed(speed));
              grd.addColorStop(1, that.getColorBySpeed(speedNext));
              ctx.strokeStyle = grd;
              ctx.lineTo(nextPixel.x, nextPixel.y);
            } else {
              lines = lines + 1;
              let lineNum = lines;
              nextArray.push([pixel, nextPixel]);
              if (
                  totalPoints[i + 1].loc_time >= starttime &&
                  totalPoints[i + 1].loc_time <= endtime
              ) {
                let partImgStart = new Image();
                partImgStart.src = startPoint;
                let next = nextPixel;
                partImgStart.onload = function () {
                  let width = [4, 8];
                  ctx.drawImage(partImgStart, next.x - 10, next.y - 30);
                  ctx.font = "lighter 14px arial";
                  ctx.fillStyle = "white";
                  ctx.fillText(
                      lineNum,
                      next.x - width[lineNum >= 10 ? 1 : 0],
                      next.y - 15
                  );
                };
              }
              if (
                  totalPoints[i].loc_time >= starttime &&
                  totalPoints[i].loc_time <= endtime
              ) {
                let current = pixel;
                let partImgEnd = new Image();
                partImgEnd.src = endPoint;
                partImgEnd.onload = function () {
                  let width = [4, 8];
                  ctx.drawImage(partImgEnd, current.x - 10, current.y - 30);
                  ctx.font = "lighter 14px arial";
                  ctx.fillStyle = "white";
                  ctx.fillText(
                      lineNum - 1,
                      current.x - width[lineNum >= 10 ? 1 : 0],
                      current.y - 15
                  );
                };
              }
            }
            if (
                totalPoints[i].loc_time >= starttime &&
                totalPoints[i + 1].loc_time <= endtime
            ) {
              ctx.stroke();
            }
          }
        }

        if (totalPoints[0].loc_time >= starttime) {
          let imgStart = new Image();
          imgStart.src = startPoint;
          imgStart.onload = function () {
            let width = [4, 8];
            ctx.drawImage(
                imgStart,
                window.map.pointToPixel(totalPoints[0]).x - 10,
                window.map.pointToPixel(totalPoints[0]).y - 30
            );
            ctx.font = "lighter 14px arial";
            ctx.fillStyle = "white";
            ctx.fillText(
                "1",
                window.map.pointToPixel(totalPoints[0]).x -
                width[lines >= 10 ? 1 : 0],
                window.map.pointToPixel(totalPoints[0]).y - 15
            );
          };
        }
        if (totalPoints[totalPoints.length - 1].loc_time <= endtime) {
          let imgEnd = new Image();
          imgEnd.src = endPoint;
          imgEnd.onload = function () {
            let width = [4, 8];
            ctx.drawImage(
                imgEnd,
                window.map.pointToPixel(totalPoints[totalPoints.length - 1]).x -
                10,
                window.map.pointToPixel(totalPoints[totalPoints.length - 1]).y -
                30
            );
            ctx.font = "lighter 14px arial";
            ctx.fillStyle = "white";
            ctx.fillText(
                lines,
                window.map.pointToPixel(totalPoints[totalPoints.length - 1]).x -
                width[lines >= 10 ? 1 : 0],
                window.map.pointToPixel(totalPoints[totalPoints.length - 1]).y -
                15
            );
          };
        }
      };
      if (totalPoints.length > 0) {
        if (
            typeof window.canvasLayer !== "undefined" ||
            typeof window.canvasLayerBack !== "undefined" ||
            typeof window.CanvasLayerPointer !== "undefined"
        ) {
          window.map.removeOverlay(window.CanvasLayerPointer);
          window.map.removeOverlay(window.canvasLayer);
          window.map.removeOverlay(window.canvasLayerBack);
        }
        window.canvasLayerBack = new window.CanvasLayer({
          map: window.map,
          update: updateBack,
        });
        window.canvasLayer = new window.CanvasLayer({
          map: window.map,
          update: update,
        });
        window.CanvasLayerPointer = new window.CanvasLayer({
          map: window.map,
          update: updatePointer,
        });
      }

      if (typeof window.pointCollection !== "undefined") {
        window.map.removeOverlay(window.pointCollection);
      }
      let options = {
        size: window.BMAP_POINT_SIZE_HUGE,
        shape: window.BMAP_POINT_SHAPE_CIRCLE,
        color: "rgba(0, 0, 0, 0)",
      };
      window.pointCollection = new window.BMap.PointCollection(
          totalPoints,
          options
      ); // 初始化PointCollection
      window.pointCollection.addEventListener("mouseover", function (e) {
        mapControl.addTrackPointOverlay(e.point, "trackpointOverlay");
      });
      window.pointCollection.addEventListener("mouseout", function () {
        mapControl.removeTrackPointOverlay("trackpointOverlay");
      });
      window.pointCollection.addEventListener("click", function (e) {
        // mapControl.removeTrackInfoBox();
        // mapControl.setTrackInfoBox(e.point);
        mapControl.removeTrackPointOverlay("trackpointonOverlay");
        mapControl.addTrackPointOverlay(e.point, "trackpointonOverlay");
      });
      window.map.addOverlay(window.pointCollection); // 添加Overlay
    },
    /**
     * view内部方法，根据速度获取对应的轨迹绘制颜色
     *
     * @param {number} speed 速度
     *
     * @return {string} 颜色RGB字符串
     */
    getColorBySpeed(speed) {
      var color = "";
      var red = 0;
      var green = 0;
      var blue = 0;
      speed = speed > 100 ? 100 : speed;
      switch (Math.floor(speed / 25)) {
        case 0:
          red = 187;
          green = 0;
          blue = 0;
          break;
        case 1:
          speed = speed - 25;
          red = 187 + Math.ceil(((241 - 187) / 25) * speed);
          green = 0 + Math.ceil(((48 - 0) / 25) * speed);
          blue = 0 + Math.ceil(((48 - 0) / 25) * speed);
          break;
        case 2:
          speed = speed - 50;
          red = 241 + Math.ceil(((255 - 241) / 25) * speed);
          green = 48 + Math.ceil(((200 - 48) / 25) * speed);
          blue = 48 + Math.ceil(((0 - 48) / 25) * speed);
          break;
        case 3:
          speed = speed - 75;
          red = 255 + Math.ceil(((22 - 255) / 25) * speed);
          green = 200 + Math.ceil(((191 - 200) / 25) * speed);
          blue = 0 + Math.ceil(((43 - 0) / 25) * speed);
          break;
        case 4:
          red = 22;
          green = 191;
          blue = 43;
          break;
      }

      red =
          red.toString(16).length === 1
              ? "0" + red.toString(16)
              : red.toString(16);
      green =
          green.toString(16).length === 1
              ? "0" + green.toString(16)
              : green.toString(16);
      blue =
          blue.toString(16).length === 1
              ? "0" + blue.toString(16)
              : blue.toString(16);
      color = "#" + red + green + blue;
      return color;
    },
  },
};
</script>
<style lang="less">
.monitor-list-item {
  height: 25px;
  border-bottom: 1px solid #ebebeb;
  margin-top: 10px;
  color: #565d68;
  cursor: pointer;
}

.monitor-list-item.is-active {
  border-bottom: 1px solid #0768FD;
}

.trackpoint_in {
  height: 8px;
  width: 8px;
  background-color: #2398ff;
  border-radius: 4px;
  margin: 3px;
}

.trackpointOverlay .trackpoint_in {
  display: none;
}

.trackpointonOverlay .trackpoint_in {
  display: block;
}

.trackpointOverlay,
.trackpointonOverlay {
  /*cursor: pointer;*/
  position: absolute;
  z-index: 50;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 12px;
  border-radius: 8px;
}

.trackpointOverlay {
  height: 12px;
  width: 12px;
  /*background-image: url(/static/images/trackpoint.png);*/
  background-color: #fff;
  border: 2px solid #000;
}

.trackpointonOverlay {
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 1px solid #2398ff;
  /*background-image: url(/static/images/trackpointon.png);*/
}
</style>
